// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backers-index-js": () => import("./../src/pages/backers/index.js" /* webpackChunkName: "component---src-pages-backers-index-js" */),
  "component---src-pages-current-event-js": () => import("./../src/pages/current-event.js" /* webpackChunkName: "component---src-pages-current-event-js" */),
  "component---src-pages-dashboard-js": () => import("./../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-events-event-list-js": () => import("./../src/pages/events/event-list.js" /* webpackChunkName: "component---src-pages-events-event-list-js" */),
  "component---src-pages-events-index-js": () => import("./../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signup-js": () => import("./../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

